import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from '@/mixins/index.js'
import '@/assets/css/base.less'
import  'animate.css'
import axios from '@/utils/axios'
Vue.config.productionTip = false
import '@/plugin/popup'
import '@/assets/js/rem.js'
import store2 from 'store2'

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import Footer from '@/components/footer'
Vue.component('Footer',Footer)
Vue.prototype.$http = axios
Vue.prototype.$store2 = store2
Vue.prototype.$store = store

import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)
Vue.mixin(mixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
