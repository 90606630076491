import { mapState, mapMutations, mapActions } from "vuex";
// import fontSpider from 'font-spider';
export default {
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    // console.log(fontSpider)
    this.getlocaluserinfo();
  },
  methods: {
    ...mapMutations(["setStateFun"]),
    getlocaluserinfo() {
      const userInfo = this.$store.state.userInfo
      if (userInfo.avatar) return;
      this.setStateFun({
        key: "userInfo",
        value: this.$store2.get("userInfo") || {},
      });
    },
  },
};
