<template>
  <div class="model textcenter">
    <div class="color1 fs-26 fw-bold  mar-bottom20">
      {{title}}
    </div>
    <div class="color9 fs-16 mar-bottom30">
      {{ content }}
    </div>
    <div class="btn ajc color1 fs-20" @click="$emit('close')">
      确定
    </div>
  </div>
</template>

<script>
export default {
name: 'MyPopup',
  props: {
    title: {
      type:String,
      default:'请求错误'
    },
    content: String
  }
}
</script>

<style scoped lang="less">
.model {
  width: 392px;
  height: 244px;
  background: #FFFFFF;
  box-shadow: 0px 13px 27px 0px rgba(153, 82, 30, 0.09);
  border-radius: 20px;
  border: 1px solid #D8AB60;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  box-shadow: 5px 5px 10px rgba(255, 250, 236, 0.5);
  padding: 50px 0 40px 0;
  box-sizing: border-box;

  .btn {
    width: 152px;
    height: 42px;
    background: #F4F1DE;
    border-radius: 11px;
    border: 2px solid #7C2E11;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>