import _axios from "axios";
import store2 from "store2";
import { codeMessage } from "./config";
import { Notification, Message } from "element-ui";
import store from "@/store";

const axios = _axios.create({
  baseURL: process.env.VUE_APP_PROXY_KEY,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

axios.interceptors.request.use(
  (cfg) => {
    cfg.headers["token"] = store2.get("token");
    // cfg.data = JSON.parse(cfg.data)
    if (cfg.data && cfg.data.type == "upload") {
      cfg.headers["Content-Type"] = "multipart/form-data";
    }
    return cfg;
  },
  (err) => [err.toString()]
);

axios.interceptors.response.use(
  (res) => {
    return new Promise((resolve) => {
      const {
        status,
        data: { code, data, msg },
      } = res;
      if (status != 200) {
        Notification({
          title: "请求错误",
          message: "请求错误，请重试！",
        });
        resolve([true, null]);
      }
      if (code != 200) {
        Notification({
          title: "请求错误",
          message: msg,
        });
        if (code == "101" || code == "100") {
          store.commit("LOGOUT");
        }
        resolve([true, null]);
      }

      resolve([null, data]);
    });
  },
  (err) => {
    return new Promise(resolve=>{
      const { response } = err;
      if (response && response.status === 500) {
        Notification.error({
          title: "错误",
          message: "服务器开小差了",
        });
        resolve(["服务器开小差了"]);
      }
      if (response && response.status) {
        Notification.error({
          title: "错误",
          message: codeMessage[response.status],
        });
        resolve(true,null);
      }
    })
 
  }
);
export default axios;
