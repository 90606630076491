import Vue from 'vue';
import MyPopup from '@/components/popup';

const PopupPlugin = {
  install(Vue, options) {
    // 添加全局方法
    Vue.prototype.$popup = function({ title, content }) {
      const PopupComponent = Vue.extend({
        // 创建动态的弹窗实例
        render(h) {
          return h(MyPopup, {
            props: {
              title: title,
              content: content
            },
            on: {
              close: this.close
            }
          });
        },
        methods: {
          close() {
            this.$destroy(); // 销毁弹窗实例
            document.body.removeChild(this.$el); // 从 DOM 中移除弹窗
          }
        }
      });

      // 创建弹窗实例并挂载到 DOM 中
      const popupInstance = new PopupComponent({
        el: document.createElement('div')
      });
      document.body.appendChild(popupInstance.$el);
    };
  }
};

Vue.use(PopupPlugin);
