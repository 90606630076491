<template>
  <div class="wrapper-box">
    <!-- 顶部 -->
    <div class="header">
      <div class="content ac space-between">
        <img src="https://rwstatic.tentons.cn/web/img/2.png" class="logo" alt="">
        <div class="right">
          <div class="space-between ac">
            <ul class="flex">
              <li v-for="item in nav" :key="item.id" @click="navClick(item)"
                :class="currentRoute.path == item.path ? 'active' : ''">
                <span>{{ item.text }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- 登录盒子 -->
        <div class="login-box">
          <!-- 未登录 -->
          <div class="login ajc" @click="navClick({ path: '/login' })" v-if="!userInfo.avatar">
            登录
          </div>
          <!-- 已登录 -->
          <template v-else>
            <el-dropdown trigger="click" @command="handleCommand">
              <div class="login-success ac">
                <img :src="userInfo.avatar" class="header-img" alt="">
                <span class="color1 fs-20">{{ userInfo.nickname }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="color:#99521E" command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </div>
      </div>
    </div>

    <router-view></router-view>

  </div>
</template>

<script>

import { mapMutations } from 'vuex'
export default {

  data() {
    return {
      loading: false,
      currentRoute: {}, // 当前的路由信息
      nav: [{
        id: 1,
        text: "首页",
        path: "/"
      },
      {
        id: 2,
        text: "自定义词书",
        path: '/custom-book'
      },
      {
        id: 3,
        text: "活动",
        path: "/activity"
      }
      ],
    }
  },
  watch: {
    '$route': {
      handler: function (val) {
        this.currentRoute = val
      }, immediate: true,
      deep: true
    }
  },

  methods: {
    ...mapMutations(['LOGOUT']),
    navClick(item) {
      if (this.currentRoute.path == item.path) return
      this.$router.push(item.path).catch(err => { })
    },
    async handleCommand(e) {
      if (e == 'logout') {
        const [err, res] = await this.$http.post('user/exitLogin')
        // this.loading = false
        if (err) return
        this.LOGOUT()
      }

    }
  }

}
</script>

<style scoped lang="less">
@import '../../assets/css/base.less';

.wrapper-box {
  // overflow-x: hidden;
}

.header {
  // padding: 0 80px 0 359px;
  z-index: 999;
  // height: 160px;
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  top: 28px;

  .login-box {
    cursor: pointer;
    position: absolute;
    right: -162px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
  }

  .login {
    cursor: pointer;
    width: 88px;
    height: 40px;
    background: #F4F1DE;
    border-radius: 10px;
    border: 2px solid #7C2E11;
    color: #7C2D10;
    font-size: 20px;


  }

  .login-success {
    // position: absolute;
    // right: -192px;

    .header-img {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      border: 2px solid #FFF;
      margin-right: 7px;
    }
  }

  .content {
    max-width: 1200px;
    // margin: 0 auto;
    // position: relative;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    // .media1();


    .logo {
      width: 151px;
      height: 57px;
    }



    .right {
      li {
        margin-right: 23px;
        color: #7C2E11;
        font-size: 20px;
        cursor: pointer;

        span {
          position: relative;
          z-index: 99;
        }

      }

      .active {
        position: relative;
        z-index: 1;

        &::after {
          content: '';
          position: absolute;
          background-image: url(https://rwstatic.tentons.cn/web/img/4.png);
          width: 100%;
          height: 13px;
          background-size: cover;
          bottom: -4px;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
  }
}
</style>