import Vue from "vue";
import VueRouter from "vue-router";
import store2 from "store2";
import Header from "@/layout/header";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/",
    component: Header,
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("../views/home/index.vue"),
      },
      {
        path: "/activity",
        name: "activity",
        component: () => import("../views/activity/index.vue"),
      },
      {
        path: "/custom-book",
        name: "custom-book",
        meta: {
          // 需要验证登录
          auth: true,
        },
        component: () => import("../views/custom-book/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // 验证登录
  if (to.meta.auth && !store2.get("token")) {
    next(`/login?redirect=${"/custom-book"}`);
  } else if (to.path == "/login" && store2.get("token")) {
    next("/");
  } else {
    next();
  }
});

export default router;
